import React,{useEffect, useState} from "react";
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useNavigate, useParams} from "react-router";
import {getEditAddress, getFormattedAddress, addLocation, updateLocation} from "../../../store/AddressList";
import {
	loginPath,
	personalInfoPath,
	locationsPath,
	registrationPath,
	emailVerifyMsgPath,
	retryRegistrationPath, userIdentityPath
} from "../../layout/body";
import {resetRegistry, getRegistry, setRegistry} from "../../../store/registry";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";

export const updateAddr = async (address, registryData, dispatch, navigate) => {
	try {
		updateActTime(dispatch);
		const response = await axios.post('/nologin/api',
			{command: {name: 'regUpdateUserAddress'}, params: {
					...address,
					userid: registryData.username,
					password: registryData.password
				}}, {timeout: 5000});
		if (response.status === 200) {
			const {data} = response;
			switch (data.status.code) {
				case 200:
					setHistoryPath(dispatch, navigate, userIdentityPath);
					break;
				case 998:
					resetRegistry(dispatch);
					setHistoryPath(dispatch, navigate, loginPath);
					break;
				default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
			}
		} else {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	} catch (error) {
		setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
	}
};

const ConfirmAddress = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const curEditAddress = useSelector(getEditAddress);
	const curFormattedAddress = useSelector(getFormattedAddress);
	const registryData = useSelector(getRegistry);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const params = useParams();
	const {pageType, origin} = params;

	const [form, setformValue] = useState('none');

	useEffect(() => {
		setformValue(pageType === 'invalidaddr' ? 'editAddr' : 'formattedAddr');
	}, []);

	const updateReg = addrToSave => {
		setRegistry(dispatch, {
			...registryData,
			homeAddress1: addrToSave.addressLine1,
			homeAddress2: addrToSave.addressLine2,
			city: addrToSave.city,
			stateAbbrev: addrToSave.stateAbbr,
			zipCode: addrToSave.zip
		});
	};

	const onSubmit = e => {
		e.preventDefault();
		const isEdit = form === 'editAddr';
		const addrToSave = isEdit ? {...curEditAddress} : {...curFormattedAddress};
		if( origin === 'locations')
			saveLocation(addrToSave);
		else if( origin === 'personal')
			saveUserInfo(addrToSave);
		else if( origin === 'register') {
			if (!isEdit) updateReg(addrToSave);
			saveRegistry(addrToSave);
		} else {
			if (!isEdit) updateReg(addrToSave);
			updateAddr(addrToSave, registryData, dispatch, navigate);
		}
	};

	const onCancel = e => {
		e.preventDefault();
		const path =
			origin === 'locations' ? locationsPath :
				origin === 'personal' ? personalInfoPath :
					origin === 'register' ? registrationPath : retryRegistrationPath;
		setHistoryPath(dispatch, navigate, path);
	};

	const onValueChange = e => {
		setformValue(e.target.value);
	};

	const saveUserInfo = async (newInfo) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'updateUserAddress', csrfSessionNonce},
			 params: newInfo
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setHistoryPath(dispatch, navigate, personalInfoPath);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setHistoryPath(dispatch, navigate, personalInfoPath,'generic.rgp.error');
						break;
				}
			} else {
				setHistoryPath(dispatch, navigate, personalInfoPath,'generic.rgp.error');
			}
		} catch (error) {
			console.log("error="+error);
			setHistoryPath(dispatch, navigate, personalInfoPath,'generic.rgp.error');
		}
	};

	const saveLocation = async (newInfo) => {
		try {
			let editType = 'addLocation';
			if( newInfo.addressId != null )
				editType = 'editLocation';
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: editType, csrfSessionNonce},
			 params: newInfo
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						const addrToSave = {
						ADDRESSID: editType === 'editLocation' ? parseInt(newInfo.addressId) : data.response.addressId,
						ADDRESS_NAME: newInfo.addressName,
						ADDRESS_LINE_1: newInfo.addressLine1,
						ADDRESS_LINE_2: newInfo.addressLine2? newInfo.addressLine2 : '',
						CITY: newInfo.city,
						STATE: newInfo.stateAbbr,
						ZIP: newInfo.zip,
						ZIP4: newInfo.zip4? newInfo.zip4:''
						};
						if( editType === 'addLocation' )
							addLocation(dispatch, addrToSave);
						else
							updateLocation(dispatch, addrToSave);
						
						setHistoryPath(dispatch, navigate, locationsPath);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			console.log("error="+error);
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}

	};

	const saveRegistry = async (newInfo) => {
		try {
			updateActTime(dispatch);
			const tempParams = {...registryData,
						secQuestionId:  registryData.secQuestionId.toString(),
						securityAnswer: registryData.secAnswer,
						sprintIpNumber: registryData.sprintIpNumber.toString(),
						advisoryRead: registryData.advisoryRead.toString(),
						certifyDisability: registryData.certifyDisability.toString(),
						language: i18n.language === 'es' ? 'SPA' : 'ENG',
						homeAddress1: newInfo.addressLine1,
						homeAddress2: newInfo.addressLine2,
						city: newInfo.city,
						stateAbbrev: newInfo.stateAbbr,
						zipCode: newInfo.zip,
						zip4: newInfo.zip4? newInfo.zip4:''
					};
			const{confirmPswd, secAnswer, ...dataParams} = tempParams;
			const response = await postWithTimeout(
				{
					command: {name: 'saveRegistry'},
					params: dataParams
			}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					case 205:
						resetRegistry(dispatch);
						setHistoryPath(dispatch, navigate, emailVerifyMsgPath);
						break;
					case 411:
						setHistoryPath(dispatch, navigate, registrationPath,'flows.registration.reginfo.35');
						break;
					case 412:
						setHistoryPath(dispatch, navigate, registrationPath,'flows.registration.reginfo.20');
						break;
					case 998:
						resetRegistry(dispatch);
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setHistoryPath(dispatch, navigate, registrationPath,'generic.rgp.error');
						break;
				}
			} else {
				setHistoryPath(dispatch, navigate, registrationPath,'generic.rgp.error');
			}

		} catch (error) {
			console.log("error="+error);
			setHistoryPath(dispatch, navigate, registrationPath,'generic.rgp.error');
		}
	};

	return ( 
			<>
			<div className={`${e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
			{ pageType === 'invalidaddr' ?
				<h1 className={`${e3Style['pt-0']}`}>{t('flows.common.invalidaddressprompt.3')}</h1>
				:
				<h1 className={`${e3Style['pt-0']}`}>{t('flows.common.addresschangeprompt.2')}</h1>
			}

			<form id="personalInfoModel" onSubmit={onSubmit}>
				{ pageType === 'invalidaddr' ?
					<>
					<div className={`${e3Style['col-xs-24']} ${sprStyle['bigger-font125']}`}>{t('flows.common.invalidaddressprompt.1')}</div>
					<h5 className={`${e3Style['col-xs-24']} ${e3Style['pt-20']}`}>{t('flows.common.invalidaddressprompt.2')}</h5>
					<div className={`${e3Style['col-xs-24']}`}>{curEditAddress.firstName} {curEditAddress.lastName}</div>
					<div className={`${e3Style['col-xs-24']}`}>{curEditAddress.addressLine1}</div>
					{ !!curEditAddress?.addressLine2?.length &&
						<div className={`${e3Style['col-xs-24']}`}>{curEditAddress.addressLine2}</div>
					}
					<div className={`${e3Style['col-xs-24']}`}>{curEditAddress.city}, {curEditAddress.stateAbbr} {curEditAddress.zip}</div>
					</>
					:
					<>
					<div className={`${e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['bigger-font125']}`}>{t('flows.common.addresschangeprompt.1')}</div>
					<div className={`${e3Style['row']} ${e3Style['sprint-account_form_item']}`}>
						<div className={`${e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']}`}>
							<div className={`${e3Style['sprint-question-steps']} ${e3Style['sprint-question-steps_2']}`}>
								<input type="radio"  value="formattedAddr" onChange={onValueChange} id="register-address-2" name="addressType"
									checked={form === 'formattedAddr'}/>
								<label className={`${e3Style['text-uppercase']}`} htmlFor="register-address-2">
									{curFormattedAddress.addressLine1} {curFormattedAddress.addressLine2},{curFormattedAddress.city}, {curFormattedAddress.stateAbbr} {curFormattedAddress.zip}-{curFormattedAddress.zip4}
								</label>
							</div>
							{ curEditAddress.city.toLowerCase() === curFormattedAddress.city.toLowerCase() &&
							  curEditAddress.stateAbbr === curFormattedAddress.stateAbbr &&
							<div className={`${e3Style['sprint-question-steps']} ${e3Style['sprint-question-steps_1']}`}>							
								<input type="radio" value="editAddr" onChange={onValueChange} id="register-address-1" name="addressType"
									checked={form === 'editAddr'}/>
								<label className={`${e3Style['text-uppercase']}`} htmlFor="register-address-1">
									{curEditAddress.addressLine1} {curEditAddress.addressLine2},{curEditAddress.city}, {curEditAddress.stateAbbr} {curEditAddress.zip}
								</label>
							</div>
							}
						</div>
					</div>
					</>
				}

				<div className={`${e3Style['row']}`}>
					<div className={`${e3Style['e3Style.colStyle']} ${e3Style['$col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={onCancel} autoFocus
							className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.back')}</button>
						{ pageType !== 'invalidaddr' &&
						<button type="submit"
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('button.next')}</button>
						}
					</div>
				</div>
			</form>
			</div>
			</>
	);
};

export default ConfirmAddress;