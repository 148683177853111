import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR, STATUS_NONE} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath, addBlockedNumberPath} from "../../layout/body";
import {
	getBlockNumbers,
	updateBlockNbr,
	delBlockNbr,
	loadBlockNums
} from "../../../store/permissionsList";
import PhoneNumberItem from "../Common/PhoneNumberItem";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";


const permissions = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const curBlockList = useSelector(getBlockNumbers);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [perms, setPerms] = useState({
		no800: false,
		noDA: false,
		dataReady: false
	});

	useEffect(() => {
		const successFunc = () => {};
		const errorFunc = ({dispatch}, response) => {
			if (response.statusType === 0) {
				switch (response.status) {
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};
		loadBlockNums(dispatch, csrfSessionNonce, successFunc, errorFunc);
		getOutdialRestNbrs();
	}, []);

	const getOutdialRestNbrs = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
					{command: {name: 'getOutdialRestrictions', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setPerms({
							...data.response,
							dataReady: true
						});
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateBlockedNumber = myPhoneNbrItem => {
		let matchedIdx = -1;
		
        if (curBlockList?.length > 0){
			matchedIdx = curBlockList.findIndex(function(value){ 
			        return value.PHONE_NUMBER === myPhoneNbrItem.PHONE_NUMBER;
			    });
			if(matchedIdx >= 0)
				setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
		}
		if(matchedIdx < 0)
			updateNumber({...myPhoneNbrItem});
	};

	const updateNumber = async (myPhoneNbrItem) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'editBlockedNumber', csrfSessionNonce},
					params: {
						id: myPhoneNbrItem.ORDERID,
						name: myPhoneNbrItem.NAME.trim(),
						phNbr: stripNonNums(myPhoneNbrItem.PHONE_NUMBER)
					}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						updateBlockNbr(dispatch,{"ORDERID": myPhoneNbrItem.ORDERID,
							"PHONE_NUMBER": stripNonNums(myPhoneNbrItem).PHONE_NUMBER,
							"NAME": myPhoneNbrItem.NAME.trim()});
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					case 426:
						setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const deleteNumber = async (myOrderId) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'deleteBlockedNumber', csrfSessionNonce},
					params: {id: myOrderId}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						delBlockNbr(dispatch,myOrderId );
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const findId = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, addBlockedNumberPath + `/addBlockedNumber`);
	};

	const updatePermissions = async (name, value) => {
		if (perms.dataReady) {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/rgpProxy',
				{RGPCommand: 'updateOutdialRestrictions', [name]: value}, {timeout: 5000});
			return response.status === 200 && response.data.status.code === 200;
		} else {
			return false;
		}
	};

	const onClick = e => {
		e.preventDefault();
		const {name, checked} = e.target;
		updatePermissions(name, checked).then(success => success && setPerms({...perms, [name]: checked}));
	};

	return (
	<>
		<StatusMsg/>
		<form className={e3Style['account-preferences_account-permissions']}>
			<fieldset className={`${e3Style['sprint-permissions']} ${e3Style['sprint-account_form_items']}`}>
			<legend><h2>{t('flows.permissions.permissions.1')}</h2></legend>
			{curBlockList?.length > 0 ?
			<div className ={e3Style['scrollable-div']}>
			{curBlockList.map(blockNbr =>
					<PhoneNumberItem key={blockNbr.ORDERID} phoneNbrItem={blockNbr} showOrderId={false}
						 onDeleteFunc={deleteNumber} onUpdateFunc={updateBlockedNumber}/>
      		)}
			</div>
			:
			<>
				<p>{t('flows.permissions.permissions.2')}</p>
			</>
			}
	</fieldset>
	<div className={e3Style.row}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		<button type="button" onClick={findId}
	        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
		{t('button.addNumber')}
	    </button>
		</div>
	</div>

		<hr />

	<fieldset class={`${e3Style['sprint-permissions']} ${e3Style['sprint-account_form_items']}`}>
		<legend><h2>{t('flows.permissions.permissions.3')}</h2></legend>

		<div class={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
			<div class={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-18']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
				<input type="checkbox" id="no800" name="no800" value="true" disabled={!perms.dataReady} checked={perms.no800} onClick={onClick}/>
				<label htmlFor="no800">{t('flows.permissions.permissions.4')}</label>
			</div>
			<div class={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-18']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
				<input type="checkbox" id="noDA" name="noDA" value="true" disabled={!perms.dataReady} checked={perms.noDA} onClick={onClick}/>
				<label htmlFor="noDirectoryAssistance">{t('flows.permissions.permissions.5')}</label>
			</div>
		</div>
	</fieldset>
	</form>
	</>
	);
};

export default permissions;