import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_NONE, STATUS_ERROR, STATUS_NOTIFICATION} from "../../../store/bodyView";
import {
	getSTSCntcts,
	setSTSCntcts,
	getSTSUserId,
	setSTSUserId,
	updateSTSCntct,
	delSTSCntct
} from "../../../store/STSContactList";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import PhoneNumberItem from "../Common/PhoneNumberItem";
import {useNavigate} from "react-router";
import {loginPath, addSTSContactPath} from "../../layout/body";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedInput from "../../util/focusWrappedInput";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const STSSettings = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const curSTSContacts = useSelector(getSTSCntcts);
	const curSTSUserId = useSelector(getSTSUserId);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const [myUserId, setMyUserId] = useState({"ID":""});
	const [prefs, setPrefs] = useState({
		stsRepeatEverything: false,
		stsRepeatNotUnderstood: false,
		dataReady: false
	});

	useEffect(() => {
//		const successFunc = () => {};
//		const errorFunc = ({dispatch}, response) => {
//			if (response.statusType === 0) {
//				switch (response.status) {
//					case 998:
//						setHistoryPath(dispatch, navigate, loginPath);
//						break;
//
//					default:
//						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
//						break;
//				}
//			} else {
//				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
//			}
//		};
//		loadSTSCntctNums(dispatch, csrfSessionNonce, successFunc, errorFunc);
		getSTSSettingState();
		getSTSContacts();
	}, []);

	const getSTSContacts = async () => {
		try {
			if(curSTSContacts === null ){
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
						{command: {name: 'listSTSContacts', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setMyUserId(prevUserId => ({...prevUserId,"ID":data.response.stsUserId}));
							setSTSUserId(dispatch,data.response.stsUserId);
							const tstList = data.response.STSContacts !== undefined ?
									data.response.STSContacts : [];
							const newList = tstList
								.map(item => ({"ORDERID":item.PHONE_NUMBER, "NAME": item.NAME, "PHONE_NUMBER": item.PHONE_NUMBER}))
								.sort((a, b) => a.ORDERID - b.ORDERID);
							setSTSCntcts(dispatch, newList );
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			}
			else
				setMyUserId(prevUserId => ({...prevUserId,"ID":curSTSUserId}));
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const getSTSSettingState = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
					{command: {name: 'getRelayPrefs', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setPrefs({
							...data.response,
							dataReady: true
						});
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateSTSContact = myPhoneNbrItem => {
		let matchedIdx = -1;

        if (curSTSContacts?.length > 0){
			if(myPhoneNbrItem.ORDERID !== stripNonNums(myPhoneNbrItem.PHONE_NUMBER)){
				matchedIdx = curSTSContacts.findIndex(
					value => value.PHONE_NUMBER === stripNonNums(myPhoneNbrItem.PHONE_NUMBER));
				if(matchedIdx >= 0)
					setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
			}
		}
		if(matchedIdx < 0)
			updateNumber({...myPhoneNbrItem});
	};

	const updateNumber = async myPhoneNbrItem => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'editSTSContact', csrfSessionNonce},
				  	params: {
						prevPhoneNbr: myPhoneNbrItem.ORDERID,
						phoneNumber: stripNonNums(myPhoneNbrItem.PHONE_NUMBER),
						name: myPhoneNbrItem.NAME.trim()
					}
				}, 
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						updateSTSCntct(dispatch,myPhoneNbrItem);
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					case 426:
						setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const deleteNumber = async myOrderId => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'deleteSTSContact', csrfSessionNonce},
				  	params: {phoneNumber: myOrderId}
				}, 
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						delSTSCntct(dispatch,myOrderId);
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const findId = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, addSTSContactPath+`/addSTSContact`);
	};

	const updatePref = async (name, value) => {
		if (prefs.dataReady) {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/rgpProxy',
				{RGPCommand: 'updateRelayPrefs', [name]: value}, {timeout: 5000});
			return response.status === 200 && response.data.status.code === 200;
		} else {
			return false;
		}
	};

	const onPrefClick = e => {
		e.preventDefault();
		const {name, checked} = e.target;
		updatePref(name, checked).then(success => success && setPrefs({...prefs, [name]: checked}));
	};

	const updateUserId = async myId => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'setSTSUserId', csrfSessionNonce},
				  	params: {
						stsUserId: myId
					}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setSTSUserId(dispatch,myUserId.ID);
						setStatusMessage(dispatch, 'Name updated!', STATUS_NOTIFICATION);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setMyUserId(prevUserId => ({...prevUserId,"ID":curSTSUserId}));
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setMyUserId( prevUserId => ({...prevUserId,"ID":curSTSUserId}));
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setMyUserId( prevUserId => ({...prevUserId,"ID":curSTSUserId}));
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
		<>
			<StatusMsg/>
			<form className={e3Style['account-preferences_account-permissions']}>
				<p>{t('flows.stscontacts.stscontacts.1')}</p>
				<fieldset className={e3Style['sprint-speech_to_speech']}>
					<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-14']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
						<input id="repeat-everything" name="stsRepeatEverything" type="checkbox" value="true"
						disabled={!prefs.dataReady} checked={prefs.stsRepeatEverything} onClick={onPrefClick}/>
						<label htmlFor="repeat-everything">{t('flows.stscontacts.stscontacts.2')}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-14']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
						<input id="repeat-when-not-understood" name="stsRepeatNotUnderstood" type="checkbox" value="true"
						disabled={!prefs.dataReady} checked={prefs.stsRepeatNotUnderstood} onClick={onPrefClick}/>
						<label htmlFor="repeat-when-not-understood">{t('flows.stscontacts.stscontacts.3')}</label>
					</div>
				</fieldset>

				<div className={`${e3Style['row']} ${sprStyle['sts-user-id']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
						<div className={`${e3Style['soar-input']} ${e3Style['soar-input--date']} ${e3Style['mb-0']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="sts-user-id">{t('flows.stscontacts.stscontacts.4')}</FocusWrappedLabel>
								<FocusWrappedInput id="sts-user-id" name="stsUserId" className={`${e3Style['sts-user-id-input']} ${e3Style['input--fluid']}`}
								                   type="text" value={myUserId.ID} onChange={e => {setMyUserId( prevUserId => ({...prevUserId,"ID":e.target.value}));}}/>
							</FocusWrappedGrp>
						</div>
						{(myUserId.ID.length > 10) ?
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										<p><span>{t('flows.stscontacts.stscontacts.8')}</span></p>
									</div>
								</div>: ''
						}
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-12']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" className={`${e3Style['button']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
						disabled={(myUserId.ID.length === 0)} onClick={e => {e.preventDefault(); updateUserId(myUserId.ID)}}>{t('button.update')}</button>
					</div>
				</div>

				<fieldset className={`${e3Style['sprint-permissions']} ${e3Style['sprint-account_form_items']}`}>
					<legend><h2 className={e3Style['pt-60']}>{t('flows.stscontacts.stscontacts.5')}</h2></legend>
					<p>{t('flows.stscontacts.stscontacts.6')}</p>
					{ curSTSContacts?.length > 0 ?
						<div className={e3Style['scrollable-div']}>
							{curSTSContacts.map(STSContact =>
								<PhoneNumberItem key={STSContact.ORDERID} phoneNbrItem={STSContact} showOrderId={false}
								                 onDeleteFunc={deleteNumber} onUpdateFunc={updateSTSContact}/>
							)}
						</div>
						:
						<>
						<p>{t('flows.stscontacts.stscontacts.7')}</p>
						</>
					}
				</fieldset>

				<div className={`${e3Style.row}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" autoFocus onClick={e => {findId(e)}}
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('button.addNumber')}
						</button>
					</div>
				</div>
			</form>
		</>
	);
}

export default STSSettings;