import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminLoginPath,adminAddTextPath} from "../../../layout/body";
import {getCsrfSessionNonce, getUserInfo, isCurrentUser} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";

const adminSTSMessages = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userData = useSelector(getUserInfo);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const isCurrentUserFlag = isCurrentUser(userData);
	const [messages, setMessagesList] = useState(null);

	useEffect(() => {
		getMessagesList(false);
	}, []);

	const getMessagesList = async (refreshFlag) => {
		try {
			if( messages == null || refreshFlag ) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getSTSMessages', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
					   	  	setMessagesList(data.response.STSMsgs);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const notesDate = (dt) => {
		let nDate = new Date(dt);
		let month = (nDate.getMonth()+1 < 10) ? '0'+(nDate.getMonth()+1) : nDate.getMonth()+1;
		let newdt = (nDate.getDate() < 10) ? '0' + nDate.getDate() : nDate.getDate();
		return month + '-' + newdt + '-' + nDate.getFullYear();
	}

	const delMessage = async (idx)  => 
	{
		try {
			if( idx !== undefined ) {
				const msgText = messages[idx]
				const msgData = {thisMsg: msgText};
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'adminDeleteSTSMsg', csrfSessionNonce}, params:msgData}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							getMessagesList(true);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	}

	return (
	<>
		<StatusMsg/>
		<form id="account-anchor" className={`${e3Style['account-preferences_notes']}`}>
		<h2>{t('prefs.adminhome.stsmessages')}</h2>
		{ messages && (Object.keys(messages).length > 0) ?
			<>
			{ Object.values(messages).map( (val,idx)  =>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-10']} ${e3Style['display-flex']}`}>
					<label className={e3Style['input--fluid']}>{val}</label>
					</div>
					<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-7']} ${e3Style['display-flex']} ${e3Style['height-100pcx']}`}>
					<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminAddTextPath+'/editMsg/'+Object.keys(messages)[idx])}}
					 className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--md']} ${e3Style['display-block']}`}>Edit</button>
					</div>
					<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-7']} ${e3Style['display-flex']} ${e3Style['height-100pcx']}`}>
					<button type="button" onClick={e => {e.preventDefault();delMessage(Object.keys(messages)[idx])}}
					 className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--md']} ${e3Style['display-block']}`}>Delete</button>
					</div>
				</div>
			)}
			</>
			:
			<p>There are no STS messages for this user.</p>
		}

	{isCurrentUserFlag && 
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
			<button type="button" autoFocus onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminAddTextPath+'/addMsg/0')}}
				className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['mt-40']}`}>{t('button.addmsg')}</button>
		</div>
	</div>
    }
	</form>
	</>
	);
};

export default adminSTSMessages;	
