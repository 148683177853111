import React from "react";
import Login from "../auth/login";
import Home from "../flows/root/home";
import BodyMenu from "./wrappers/bodyMenu";
import AccountList from "../flows/Account/accountList";
import VerificationData from "../flows/Account/verificationData";
import {Navigate, Route, Routes} from "react-router";
import VerifyIdent from "../flows/Common/verifyIdent";
import ReseveNumber from "../flows/Account/reseveNumber";
import NewNPA from "../flows/Common/newNPA";
import Preferences from "../flows/Preferences/preferences";
import ConfirmDeleteNumber from "../flows/Account/confirmDeleteNumber";
import EmergencyNumbers from "../flows/Emergency/emergencyNumbers";
import AddNumber from "../flows/Common/addNumber";
import FreqNumbers from "../flows/FreqDialed/freqNumbers";
import STSSettings from "../flows/STSSetting/stsSetting";
import AdminLogin from "../auth/adminLogin";
import Permissions from "../flows/Permissions/permissions";
import PersonalInfo from "../flows/PersonalInfo/PersonalInfo";
import ConfirmAddress from "../flows/Common/confirmAddress";
import Locations from "../flows/Locations/Locations";
import EditAddress from "../flows/Locations/EditAddress";
import AdminBodyMenu from "./wrappers/adminBodyMenu";
import QueryUser from "../flows/admin/SelectUser/queryUser";
import ShowUser from "../flows/admin/UserData/showUser";
import UserList from "../flows/admin/SelectUser/userList";
import QueryCanceledUser from "../flows/admin/SelectUser/queryCanceledUser";
import UserListCanceled from "../flows/admin/SelectUser/userListCanceled";
import AccountSecurity from "../flows/Security/manageAccount";
import Notes from "../flows/Notes/notes";
import AddNote from "../flows/Notes/addNote";
import PrintInfo from "../flows/Print/PrintInfo";
import AdminNotes from "../flows/admin/Notes/adminNotes";
import AddAdminNote from "../flows/admin/Notes/addAdminNote";
import ValidateUser from "../flows/admin/ValidateUser/validateUser";
import UserDetails from "../flows/admin/EditUser/userDetails";
import ChangeLandline from "../flows/admin/Landline/changeLandline";
import AdminSTSMessages from "../flows/admin/STSMessage/adminSTSMessages";
import AdminAddText from "../flows/Common/addAdminText";
import DeleteUser from "../flows/admin/DeleteUser/deleteUser";
import ShowRegScan from "../flows/admin/RegScan/showRegScan";
import Intervention from "../flows/admin/Intervention/interventions";
import TdNumDisplay from "../flows/admin/ManageTdNum/tdNumDisplay";
import TdNumAddNPA from "../flows/admin/ManageTdNum/tdNumAddNPA";
import TdNumDelete from "../flows/admin/ManageTdNum/tdNumDelete";
import PortRequest from "../flows/Common/portRequest";
import NoMenu from "./wrappers/noMenu";
import Registration from "../flows/Registration/registration";
import EmailVerifyMessage from "../flows/Registration/emailVerifyMessage";
import VerifyEmail from "../flows/Registration/verifyEmail";
import UserIdentity from "../flows/Registration/userIdentity";
import EmailNotVerified from "../auth/emailNotVerified";
import Errors from "../flows/root/errors";
import Ipblock from "../flows/root/ipblock";
import Gblex from "../flows/root/gblex";
import RequestUsername from "../auth/requestUsername";
import VerifySecAnswer from "../auth/verifySecAnswer";
import ChgPassword from "../auth/chgPassword";
import RetryRegistration from "../flows/Registration/retryRegistration";


export const rootPath = "/";
export const loginPath = "/auth/login";
export const adminLoginPath = "/auth/adminLogin";
export const emailNotVerifiedPath = "/auth/emailNotVerified";
export const accountListPath = "/Account/accountList";
export const relayVerifDataPath = "/Account/relayVerifData";
export const verifyIdentBasePath = "/Account/verifyIdent";
export const reserveNumberPath = "/Account/reserveNumber";
export const regReserveNumberPath = "/Account/regReserveNumber";
export const newNPAPath = "/Account/newNPA";
export const relayConfirmDelNumPath = "/Account/relayConfirmDelNum";
export const accountPortNumPath = "/Account/accountPortNum";
export const regPortNumPath = "/Account/regPortNum";
export const preferencesPath = "/Preferences/relayPrefs";
export const emergencyNumbersPath = "/Emergency/accountList";
export const addEmergencyNumberPath = "/Emergency/addEmergencyNumber";
export const freqNumbersPath = "/FreqDialed/accountList";
export const addFreqNumbersPath = "/FreqDialed/addNumber";
export const stsSettingsPath = "/STSContacts/accountList";
export const addSTSContactPath = "/STSContacts/addNumber";
export const permissionsPath = "/Permissions/accountList";
export const addBlockedNumberPath = "/Permissions/addBlockedNumber";
export const personalInfoPath = "/PersonalInfo/accountList";
export const confirmAddressPath = "/PersonalInfo/confirmAddress";
export const locationsPath = "/Addresses/accountList";
export const editAddressPath = "/Addresses/editAddress";
export const adminUserDataShowUserPath = "/Admin/UserData/showUser";
export const adminManagePhoneNumsTablePath = "/Admin/ManagePhoneNums/display";
export const adminNotesMgrNotesPath = "/Admin/NotesMgr/notes";
export const adminInterventionsShowInterventionsPath = "/Admin/Interventions/showInterventions";
export const adminRegScanShowRegScanPath = "/Admin/RegScan/showRegScan";
export const adminValidateUserValidatePath = "/Admin/ValidateUser/validate";
export const adminEditUserUserDetailsPath = "/Admin/EditUser/userDetails";
export const adminChangeLandlineChangePath = "/Admin/Landline/changeLandline";
export const adminDeleteUserDeletePath = "/Admin/DeleteUser/delete";
export const adminSTSMessagesMessagesPath = "/Admin/STSMessages/messages";
export const adminCanceledUserCanceledUserPath = "/Admin/CanceledUser/canceledUser";
export const adminSelectUserQueryPath = "/Admin/SelectUser/queryUser";
export const adminSelectUserUserListPath = "/Admin/SelectUser/userList";
export const adminSelectCanceledUserQueryPath = "/Admin/SelectCanceledUser/query";
export const adminSelectCanceledUserUserListPath = "/Admin/SelectCanceledUser/userList";
export const registrationPath = "/Registration";
export const retryRegistrationPath = "/RetryRegistration";
export const regConfirmAddressPath = "/Registration/confirmAddress";
export const emailVerifyMsgPath = "/Registration/EmailVerifyMsg"
export const securityPath = "/Security/accountList";
export const notesPath = "/Notes/accountList";
export const addNotesPath = "/Notes/addNote";
export const printInfoPath = "/Print/accountList";
export const addAdminNotesPath = "/Admin/NotesMgr/addAdminNote";
export const adminUserDetailsPath = "/Admin/EditUser/userDetails";
export const adminAddTextPath = "/Admin/AdminAddText";
export const adminManagePhoneNumsAddNPAPath = "/Admin/ManagePhoneNums/addNPA";
export const adminManagePhoneNumsDeletePath = "/Admin/ManagePhoneNums/delete";
export const userIdentityPath = "/Registration/UserIdentity";
export const errorsPath = "/nologin/errors";
export const ipblockPath = "/nologin/ipblock";
export const gblexPath = "/nologin/gblex";
export const requestUsernamePath = "/auth/requestUsername";
export const verifySecAnswerPath = "/auth/verifySecAnswer";
export const chgPasswordPath = "/auth/chgPassword";

const AccountListWithMenu = BodyMenu(AccountList);
const VerifDataWithMenu = BodyMenu(VerificationData);
const AccountVerifyIdentWithMenu = BodyMenu(VerifyIdent);
const AccountReserveNumberWithMenu = BodyMenu(ReseveNumber);
const RegReserveNumberWithNoMenu = NoMenu(ReseveNumber);
const AccountNewNPAWithMenu = BodyMenu(NewNPA);
const AccountConfirmDelNumWithMenu = BodyMenu(ConfirmDeleteNumber);
const AccountPortNumberWithMenu = BodyMenu(PortRequest);
const AccountPortNumberNoMenu = NoMenu(PortRequest);
const PreferencesWithMenu = BodyMenu(Preferences);
const EmergencyNumbersWithMenu = BodyMenu(EmergencyNumbers);
const AddNumberWithMenu = BodyMenu(AddNumber);
const FreqNumbersWithMenu = BodyMenu(FreqNumbers);
const STSSettingsWithMenu = BodyMenu(STSSettings);
const PermissionsWithMenu = BodyMenu(Permissions);
const PersonalInfoWithMenu = BodyMenu(PersonalInfo);
const ConfirmAddressWithMenu = BodyMenu(ConfirmAddress);
const LocationsWithMenu = BodyMenu(Locations);
const EditAddressWithMenu = BodyMenu(EditAddress);
const AdminQueryUserWithMenu = AdminBodyMenu(QueryUser);
const AdminShowUserWithMenu = AdminBodyMenu(ShowUser);
const AdminUserListWithMenu = AdminBodyMenu(UserList);
const AdminQueryCanceledUserWithMenu = AdminBodyMenu(QueryCanceledUser);
const AdminCanceledUserListWithMenu = AdminBodyMenu(UserListCanceled);
const AccountSecurityWithMenu = BodyMenu(AccountSecurity);
const NotesWithMenu = BodyMenu(Notes);
const AddNotesWithMenu = BodyMenu(AddNote);
const PrintInfoWithMenu = BodyMenu(PrintInfo);
const AdminNotesWithMenu = AdminBodyMenu(AdminNotes);
const AddAdminNotesWithMenu = AdminBodyMenu(AddAdminNote);
const AdminValidateUserWithMenu = AdminBodyMenu(ValidateUser);
const AdminUserDetailsWithMenu = AdminBodyMenu(UserDetails);
const AdminLandlineWithMenu = AdminBodyMenu(ChangeLandline);
const AdminSTSMessagesWithMenu = AdminBodyMenu(AdminSTSMessages);
const AdminAddTextWithMenu = AdminBodyMenu(AdminAddText);
const AdminDeleteUserWithMenu = AdminBodyMenu(DeleteUser);
const AdminRegScanWithMenu = AdminBodyMenu(ShowRegScan);
const AdminInterventionWithMenu = AdminBodyMenu(Intervention);
const AdminTdNumDisplayWithMenu = AdminBodyMenu(TdNumDisplay);
const AdminTdNumAddNPAWithMenu = AdminBodyMenu(TdNumAddNPA);
const AdminTdNumDeleteWithMenu = AdminBodyMenu(TdNumDelete);
const RegisterWithNoMenu = NoMenu(Registration);
const RetryRegisterWithNoMenu = NoMenu(RetryRegistration);
const ConfirmAddressWithNoMenu = NoMenu(ConfirmAddress);
const EmailVerifyMsgWithNoMenu = NoMenu(EmailVerifyMessage);
const VerifyEmailWithNoMenu = NoMenu(VerifyEmail);
const UserIdentityWithNoMenu = NoMenu(UserIdentity);
const RequestUsernameWithNoMenu = NoMenu(RequestUsername);
const VerifySecAnswerWithNoMenu = NoMenu(VerifySecAnswer);
const ChgPasswordWithNoMenu = NoMenu(ChgPassword);


const Body = () => {
	return (
		<Routes>
			<Route path={loginPath} element={<Login/>}/>
			<Route path={adminLoginPath} element={<AdminLogin/>}/>
			<Route path={emailNotVerifiedPath} element={<EmailNotVerified/>}/>
			<Route path={accountListPath} element={<AccountListWithMenu pageTitle="Account"/>}/>
			<Route path={relayVerifDataPath} element={<VerifDataWithMenu pageTitle="Account"/>}/>
			<Route path={verifyIdentBasePath + "/:phone"} element={<AccountVerifyIdentWithMenu pageTitle="Account"/>}/>
			<Route path={reserveNumberPath + "/:phone"} element={<AccountReserveNumberWithMenu pageTitle="Account"/>}/>
			<Route path={regReserveNumberPath + "/:phone/:source"} element={<RegReserveNumberWithNoMenu pageTitle=""/>}/>
			<Route path={newNPAPath} element={<AccountNewNPAWithMenu pageTitle="Account"/>}/>
			<Route path={relayConfirmDelNumPath} element={<AccountConfirmDelNumWithMenu pageTitle="Account"/>}/>
			<Route path={accountPortNumPath} element={<AccountPortNumberWithMenu pageTitle="Account"/>}/>
			<Route path={regPortNumPath+ "/:source"} element={<AccountPortNumberNoMenu pageTitle=""/>}/>
			<Route path={preferencesPath} element={<PreferencesWithMenu pageTitle="Preferences"/>}/>
			<Route path={emergencyNumbersPath} element={<EmergencyNumbersWithMenu pageTitle="EmergencyNumbers"/>}/>
			<Route path={addEmergencyNumberPath + "/:addType"} element={<AddNumberWithMenu pageTitle="EmergencyNumbers"/>}/>
			<Route path={freqNumbersPath} element={<FreqNumbersWithMenu pageTitle="Frequently Dialed"/>}/>
			<Route path={addFreqNumbersPath + "/:addType"} element={<AddNumberWithMenu pageTitle="Frequently Dialed"/>}/>
			<Route path={stsSettingsPath} element={<STSSettingsWithMenu pageTitle="Speech to Speech"/>}/>
			<Route path={addSTSContactPath + "/:addType"} element={<AddNumberWithMenu pageTitle="Speech to Speech"/>}/>
			<Route path={permissionsPath} element={<PermissionsWithMenu pageTitle="Permissions"/>}/>
			<Route path={personalInfoPath} element={<PersonalInfoWithMenu pageTitle="Personal Information"/>}/>
			<Route path={addBlockedNumberPath + "/:addType"} element={<AddNumberWithMenu pageTitle="Add Phone Number"/>}/>
			<Route path={confirmAddressPath + "/:origin/:pageType"} element={<ConfirmAddressWithMenu pageTitle="Confirm Address"/>}/>
			<Route path={locationsPath} element={<LocationsWithMenu pageTitle="Emergency Location"/>}/>
			<Route path={editAddressPath + "/:editType/:addressId"} element={<EditAddressWithMenu pageTitle="Emergency Location"/>}/>
			<Route path={adminSelectUserQueryPath} element={<AdminQueryUserWithMenu pageTitle="Select User"/>}/>
			<Route path={adminUserDataShowUserPath} element={<AdminShowUserWithMenu pageTitle="User Info"/>}/>
			<Route path={adminSelectUserUserListPath + "/:nametype/:first/:last"} element={<AdminUserListWithMenu pageTitle="Select User"/>}/>
			<Route path={adminSelectCanceledUserQueryPath} element={<AdminQueryCanceledUserWithMenu pageTitle="Select Deleted User"/>}/>
			<Route path={adminSelectCanceledUserUserListPath + "/:srchtype/:srchprm1"}>
				<Route path=":srchprm2" element={<AdminCanceledUserListWithMenu pageTitle="Select Deleted User"/>}/>
				<Route path="" element={<AdminCanceledUserListWithMenu pageTitle="Select Deleted User"/>}/>
			</Route>
			<Route path={securityPath} element={<AccountSecurityWithMenu pageTitle="Account Security"/>}/>
			<Route path={notesPath} element={<NotesWithMenu pageTitle="Notes"/>}/>
			<Route path={addNotesPath + "/:newId"} element={<AddNotesWithMenu pageTitle="Notes"/>}/>
			<Route path={printInfoPath} element={<PrintInfoWithMenu pageTitle="Print"/>}/>
			<Route path={adminNotesMgrNotesPath} element={<AdminNotesWithMenu pageTitle="Agent Notes"/>}/>
			<Route path={addAdminNotesPath} element={<AddAdminNotesWithMenu pageTitle="Agent Notes"/>}/>
			<Route path={adminValidateUserValidatePath} element={<AdminValidateUserWithMenu pageTitle="Validate User Identity"/>}/>
			<Route path={adminUserDetailsPath} element={<AdminUserDetailsWithMenu pageTitle="Edit User"/>}/>
			<Route path={adminChangeLandlineChangePath} element={<AdminLandlineWithMenu pageTitle="Change Landline"/>}/>
			<Route path={adminSTSMessagesMessagesPath} element={<AdminSTSMessagesWithMenu pageTitle="STS Messages"/>}/>
			<Route path={adminAddTextPath + "/:actionType/:itemIdx"} element={<AdminAddTextWithMenu pageTitle="Admin Text"/>}/>
			<Route path={adminDeleteUserDeletePath} element={<AdminDeleteUserWithMenu pageTitle="Delete User"/>}/>
			<Route path={adminRegScanShowRegScanPath} element={<AdminRegScanWithMenu pageTitle="Show Reg Scan"/>}/>
			<Route path={adminInterventionsShowInterventionsPath} element={<AdminInterventionWithMenu pageTitle="Interventions"/>}/>
			<Route path={adminManagePhoneNumsTablePath} element={<AdminTdNumDisplayWithMenu pageTitle="Manage Phone Numbers"/>}/>
			<Route path={adminManagePhoneNumsAddNPAPath} element={<AdminTdNumAddNPAWithMenu pageTitle="Manage Phone Numbers"/>}/>
			<Route path={adminManagePhoneNumsDeletePath} element={<AdminTdNumDeleteWithMenu pageTitle="Manage Phone Numbers"/>}/>
			<Route path={regConfirmAddressPath + "/:origin/:pageType"} element={<ConfirmAddressWithNoMenu pageTitle="Confirm Address"/>}/>
			<Route path={emailVerifyMsgPath} element={<EmailVerifyMsgWithNoMenu pageTitle="Verify Email"/>}/>
			<Route path={userIdentityPath} element={<UserIdentityWithNoMenu pageTitle=""/>}/>
			<Route path={registrationPath + "/:verifyId/:verifyCheck"} element={<VerifyEmailWithNoMenu pageTitle=""/>}/>
			<Route path={registrationPath} element={<RegisterWithNoMenu pageTitle="Registration"/>}/>
			<Route path={retryRegistrationPath} element={<RetryRegisterWithNoMenu pageTitle="Retry Registration"/>}/>
			<Route path={errorsPath + "/:errorMsgHead/:errorMsg"} element={<Errors pageTitle="Error"/>}/>
			<Route path={ipblockPath + "/:ipaddr/:fwdaddr"} element={<Ipblock pageTitle="IP Block"/>}/>
			<Route path={gblexPath} element={<Gblex pageTitle="Exception"/>}/>
			<Route path={requestUsernamePath} element={<RequestUsernameWithNoMenu pageTitle=""/>}/>
			<Route path={verifySecAnswerPath} element={<VerifySecAnswerWithNoMenu pageTitle=""/>}/>
			<Route path={chgPasswordPath} element={<ChgPasswordWithNoMenu pageTitle=""/>}/>
			<Route path={rootPath} element={<Home/>}/>
			<Route path="*" element={<Navigate to={rootPath} replace/>}/>
		</Routes>
	);
};

export default Body;
