import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_NONE, STATUS_ERROR, STATUS_NOTIFICATION} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {setEditAddress, setFormattedAddress, setStateList, getStateList} from "../../../store/AddressList"
import {loginPath, confirmAddressPath} from "../../layout/body";
import Joi from "joi";
import { useValidator } from "react-joi";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedSelect from "../../util/focusWrappedSelect";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const PersonalInfo = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateList = useSelector(getStateList);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [origUserInfo, setOrigUserInfo] = useState({
		firstName : '',
		lastName : '',
		addressLine1 : '',
		addressLine2 : '',
		city : '',
		stateAbbr : '',
		zip : '',
		zip4 : '',
		dataReady: false
	});

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			addressLine1 : '',
			addressLine2 : '',
			city : '',
			stateAbbr : '',
			zip : '',
			zip4 : '',
		},
 	    schema: Joi.object({
			addressLine1: Joi.string().trim().min(1).required().messages({
				'string.base': 'addressLine1.required.error',
				'string.empty': 'addressLine1.required.error',
				'any.required': 'addressLine1.required.error'
			}),
			city: Joi.string().trim().min(1).required().messages({
				'string.base': 'city.required.error',
				'string.empty': 'city.required.error',
				'any.required': 'city.required.error',
				'string.pattern.base': 'city.required.error'
			}),
			stateAbbr: Joi.string().trim().min(2).required().messages({
				'string.base': 'stateAbbr.required.error',
				'string.empty': 'stateAbbr.required.error',
				'any.required': 'stateAbbr.required.error',
				'string.pattern.base': 'stateAbbr.required.error'
			}),
			zip: Joi.string().required().pattern(/^\d{5}$/).messages({
				'string.base': 'zip.invalidFormat.error',
				'string.empty': 'zip.required.error',
				'any.required': 'zip.required.error',
				'string.pattern.base': 'zip.invalidFormat.error'
			})
		}),
		explicitCheck: {
			addressLine1: false,
			city: false,
			stateAbbr: false,
			zip:false
		}
	});

	useEffect(() => {
		getUserInfo();
	}, []);
	
	const getUserInfo = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'getUserInfoEnhanced', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						let myInfo = {...data.response};
						setData((old) => ({
							...old,
							addressLine1: myInfo.addressLine1,
							addressLine2: myInfo.addressLine2? myInfo.addressLine2 : '',
							city: myInfo.city,
							stateAbbr: myInfo.stateAbbrev,
							zip: myInfo.zip,
							zip4: myInfo.zip4
						}));

						setOrigUserInfo({
							firstName : myInfo.firstName,
							lastName : myInfo.lastName,
							addressLine1 : myInfo.addressLine1,
							addressLine2 : myInfo.addressLine2? myInfo.addressLine2 : '',
							city : myInfo.city,
							stateAbbr : myInfo.stateAbbrev,
							zip : myInfo.zip,
							zip4 : myInfo.zip4,
							dataReady: true
						});
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			if(stateList.length === 0){
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
						{command: {name: 'getStates'}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setStateList(dispatch, data.response.statesList);
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			}

		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = e => {
		const {name, value} = e.target;
		setData((old) => ({
			...old,
			[name]: value
		}));
	};

	const restoreFields = () =>{
		setData((old) => ({
			...old,
			addressLine1: origUserInfo.addressLine1,
			addressLine2: origUserInfo.addressLine2,
			city: origUserInfo.city,
			stateAbbr: origUserInfo.stateAbbr,
			zip: origUserInfo.zip,
			zip4: origUserInfo.zip4
		}));
	}
	
	const onCancel = e => {
		e.preventDefault();
		restoreFields();
	}

	const onSubmit = e => {
		e.preventDefault();
		checkUserInfo({...state.$data});
	}

	const checkUserInfo = async (newInfo) => {
		const newAddress = {...newInfo,
							addressLine1: newInfo.addressLine1.trim(),
							addressLine2: newInfo.addressLine2? newInfo.addressLine2.trim() : '',
							city: newInfo.city.trim(),
							zip4: ''};

		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'formatAddress'}, 
			 params: newAddress
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						let formattedAddress = {...data.response, 
							addressLine2: data.response.addressLine2? data.response.addressLine2 : '',
							zip4: data.response.zip4? data.response.zip4 : ''};
						if( formattedAddress.addressLine1 === newAddress.addressLine1.toUpperCase() &&
							formattedAddress.addressLine2 === newAddress.addressLine2.toUpperCase() &&
							formattedAddress.city === newAddress.city.toUpperCase() &&
							formattedAddress.stateAbbr === newAddress.stateAbbr &&
							formattedAddress.zip === newAddress.zip) {
							saveUserAddress(formattedAddress);
						}
						else{
							setFormattedAddress(dispatch, formattedAddress);
							setEditAddress(dispatch, newAddress);
							setHistoryPath(dispatch, navigate, confirmAddressPath+ `/personal/selectaddr`);
						}
						break;

					case 419:
						setEditAddress(dispatch, newAddress);
						setHistoryPath(dispatch, navigate, confirmAddressPath+ `/personal/invalidaddr`);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						restoreFields();
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				restoreFields();
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

		} catch (error) {
			restoreFields();
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveUserAddress = async (newInfo) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'updateUserAddress', csrfSessionNonce},
			 params: newInfo
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setStatusMessage(dispatch, 'successfully saved!', STATUS_NOTIFICATION);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						restoreFields();
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				restoreFields();
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

		} catch (error) {
			restoreFields();
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
		<>
			<StatusMsg/>
			<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
			{ origUserInfo.dataReady ?
				<form id="personalInfoModel" noValidate autoComplete="off" onSubmit={onSubmit}>
					<fieldset>
						<legend className={e3Style['form-title']}>
							<h2>{t('flows.personalinfo.getinfo.1')}</h2>
						</legend>
						<div className={e3Style['row']}>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
								<label className={e3Style['text-uppercase']}>{t('flows.personalinfo.getinfo.2')}</label>
								<h3 className={e3Style['input--fluid']}>{origUserInfo.firstName}</h3>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
								<label className={`${e3Style['text-uppercase']}`}>{t('flows.personalinfo.getinfo.3')}</label>
								<h3 className={e3Style['input--fluid']}>{origUserInfo.lastName}</h3>
							</div>
						</div>
						<div className={e3Style['row']}>&nbsp;</div>

						<div className={e3Style['row']}>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
								<FocusWrappedGrp baseAriaId="addrLine1Err" cptErrCount={state.$errors.addressLine1.length}>
									<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="addressLine1">
										*{t('flows.personalinfo.getinfo.4')}</FocusWrappedLabel>
									<FocusWrappedInput id="addressLine1" name="addressLine1" className={e3Style['input--fluid']}
									                   initialFocus placeholder={t('flows.personalinfo.getinfo.9')} type="text"
									                   value={state.$data.addressLine1} onChange={e => updateField(e)}
									                   onBlur={() => setExplicitField('addressLine1', true)}/>
									{!!state.$errors.addressLine1.length &&
										<div className={e3Style['sprint-alert--error']}>
											<div className={e3Style['alert--error_message']}>
												{state.$errors.addressLine1.map((err, idx) => <p index={idx} id={'addrLine1Err-' + idx}><span>{t(err.$message)}</span></p>)}
											</div>
										</div>
									}
								</FocusWrappedGrp>
							</div>
						</div>
						<div className={e3Style['row']}>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
								<FocusWrappedGrp>
									<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="addressLine2">
										{t('flows.personalinfo.getinfo.5')}</FocusWrappedLabel>
									<FocusWrappedInput id="addressLine2" name="addressLine2" className={e3Style['input--fluid']}
									                   type="text" value={state.$data.addressLine2} onChange={e => updateField(e)}
									                   onBlur={() => setExplicitField('addressLine2', true)}/>
								</FocusWrappedGrp>
							</div>
						</div>
						<div className={e3Style['row']}>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
								<FocusWrappedGrp baseAriaId="cityErr" cptErrCount={state.$errors.city.length}>
									<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="city">
										*{t('flows.personalinfo.getinfo.6')}</FocusWrappedLabel>
									<FocusWrappedInput id="city" name="city" className={e3Style['input--fluid']} type="text"
									                   value={state.$data.city} onChange={e => updateField(e)}
									                   onBlur={() => setExplicitField('city', true)}/>
									{!!state.$errors.city.length &&
										<div className={e3Style['sprint-alert--error']}>
											<div className={e3Style['alert--error_message']}>
												{state.$errors.city.map((err, idx) => <p index={idx} id={'cityErr-' + idx}><span>{t(err.$message)}</span></p>)}
											</div>
										</div>
									}
								</FocusWrappedGrp>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-6']}`}>
								<FocusWrappedGrp baseAriaId="stateErr" cptErrCount={state.$errors.stateAbbr.length}>
									<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="stateAbbr">
										*{t('flows.personalinfo.getinfo.7')}</FocusWrappedLabel>
									<FocusWrappedSelect id="stateAbbr" name="stateAbbr" onChange={e => updateField(e)}
									                    onBlur={() => setExplicitField('stateAbbr', true)}>
										<option value="">&nbsp;</option>
										{!!stateList.length && stateList.map(stateAbrev =>
											<option value={stateAbrev.STATE_ABBR} selected={stateAbrev.STATE_ABBR === state.$data.stateAbbr}>
												{stateAbrev.STATE_ABBR}</option>)}
									</FocusWrappedSelect>
									{!!state.$errors.stateAbbr.length &&
										<div className={e3Style['sprint-alert--error']}>
											<div className={e3Style['alert--error_message']}>
												{state.$errors.stateAbbr.map((err, idx) => <p index={idx} id={'stateErr-' + idx}><span>{t(err.$message)}</span></p>)}
											</div>
										</div>
									}
								</FocusWrappedGrp>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-6']}`}>
								<FocusWrappedGrp baseAriaId="zipErr" cptErrCount={state.$errors.zip.length}>
									<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="zip">
										*{t('flows.personalinfo.getinfo.8')}</FocusWrappedLabel>
									<FocusWrappedInput type="number" name="zip" value={state.$data.zip} id="zip"
									                   className={e3Style['input--fluid']} onChange={e => updateField(e)}
									                   onBlur={() => setExplicitField('zip', true)} />
									{!!state.$errors.zip.length &&
										<div className={e3Style['sprint-alert--error']}>
											<div className={e3Style['alert--error_message']}>
												{state.$errors.zip.map((err, idx) => <p index={idx} id={'zipErr-' + idx}><span>{t(err.$message)}</span></p>)}
											</div>
										</div>
									}
								</FocusWrappedGrp>
							</div>
						</div>
					</fieldset>
					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
							<button type="button"  
							className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
							onClick={onCancel}>{t('button.cancel')} </button>
							<button type="submit" onClick={validate} disabled={state.$auto_invalid}
							className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
								{t('button.submit')}</button>
						</div>
					</div>
				</form>
				:
				<>
				</>
			}
			</div>
		</>
	);
}

export default PersonalInfo;